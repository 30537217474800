import { useEffect, useState } from "react";
import { axios } from "../axios";
import { showToast } from "../utils/showToast";

export function NewUsersTable() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    try {
      const response = await axios.get("users?status=false");
      setData(response.data.result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Remove the loading splash when data is fetched
  useEffect(() => {
    if (data.length > 0) {
      setLoading(false);
    }
  }, [data]);

  // If there's no data to fetch, timeout the loading splash
  useEffect(() => {
    const fetchTimeout = setTimeout(() => {
      if (loading) {
        setLoading(false);
      }
    }, 3000);
    return () => clearTimeout(fetchTimeout);
  }, [loading]);

  const approveUser = async (userId) => {
    try {
      const response = await axios.put("users/approve/" + userId);
      setData((prevData) => prevData.filter((user) => user._id !== userId));
      showToast(response.data.message);
    } catch (error) {
      console.error("error: ", error);
    }
  };

  const deleteUser = async (userId) => {
    try {
      const response = await axios.delete("users/" + userId);
      setData((prevData) => prevData.filter((user) => user._id !== userId));
      showToast(response.data.message);
    } catch (error) {
      console.error("error: ", error);
    }
  };

  return (
    <div>
      {loading ? (
        <div className="loading-splash">
          <h1 className="loading-text">LOADING</h1>
        </div>
      ) : (
        <div>
          {data.map((user) => (
            <div className="new-user-details" key={user._id}>
              <p>
                Name: {user.firstName} {user.lastName}
              </p>
              <p>Email: {user.email}</p>
              <div className="form-buttons">
                <button
                  className="submit-button"
                  onClick={() => approveUser(user._id)}
                >
                  Approve
                </button>
                <button
                  className="delete-button"
                  onClick={() => deleteUser(user._id)}
                >
                  Deny
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
