export const orderValidation = (componentDetails) => {
  const {
    manufacturer,
    model,
    description,
    unitNumber,
    workOrderNumber,
    priority,
    notes,
  } = componentDetails;

  if (manufacturer === "") {
    return "Please choose a manufacturer.";
  }

  if (model === "") {
    return "Please choose a model.";
  }

  if (description === "") {
    return "Please select a component.";
  }

  const validUnitNumber = /^([a-zA-Z]{2}\d{4})$/;
  if (!validUnitNumber.test(unitNumber)) {
    return "Unit number must be 6 character and be in the standard unit number format, e.g. 'RD1234', 'EX4567'.";
  }

  const validWorkOrder = /^\d{7}$/;
  if (!validWorkOrder.test(workOrderNumber)) {
    return "Work order number must be a number of 7 characters, e.g. '7438323'.";
  }

  if (priority === "") {
    return "Please select a priority.";
  }

  if (notes.length > 120) {
    return "Notes has a maximum character count of 120.";
  }

  return "";
};
