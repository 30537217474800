import { useEffect, useState } from "react";
import UpdateDetailsModalForm from "./modalforms/UpdateDetailsModalForm";
import ChangePasswordModalForm from "./modalforms/ChangePasswordModalForm";
import ApproveUsersModalForm from "./modalforms/ApproveUsersModalForm";
import { axios } from "../axios";

export function SettingsButtons() {
  const [isDetailsModalOpen, setDetailsModalOpen] = useState(false);
  const [isPasswordModalOpen, setPasswordModalOpen] = useState(false);
  const [isApproveModalOpen, setApproveModalOpen] = useState(false);
  const [adminUser, setAdminUser] = useState(false);

  // Retrieve user details to check for admin
  const fetchData = async () => {
    const user = await axios.get(
      "users?userId=" + localStorage.getItem("userId")
    );
    const response = await axios.get("roles/" + user.data.result.role);

    if (response.data.role === "admin") {
      setAdminUser(true);
    }
  };
  useEffect(() => {
    fetchData();
  }, [adminUser]);

  const openDetailsModal = () => {
    setDetailsModalOpen(true);
  };
  const openPasswordModal = () => {
    setPasswordModalOpen(true);
  };
  const openApproveModal = () => {
    setApproveModalOpen(true);
  };
  const closeDetailsModal = () => {
    setDetailsModalOpen(false);
  };
  const closePasswordModal = () => {
    setPasswordModalOpen(false);
  };
  const closeApproveModal = () => {
    setApproveModalOpen(false);
  };

  return (
    <div className="settings-buttons-container">
      <button className="table-button" onClick={openDetailsModal}>
        Update User Details
      </button>
      <button className="table-button" onClick={openPasswordModal}>
        Change Password
      </button>
      <button
        disabled={!adminUser}
        className="table-button"
        onClick={openApproveModal}
      >
        Approve New Users
      </button>

      <UpdateDetailsModalForm
        isOpen={isDetailsModalOpen}
        closeModal={closeDetailsModal}
      />
      <ChangePasswordModalForm
        isOpen={isPasswordModalOpen}
        closeModal={closePasswordModal}
      />
      <ApproveUsersModalForm
        isOpen={isApproveModalOpen}
        closeModal={closeApproveModal}
      />
    </div>
  );
}
