export const changePasswordValidation = (passwords) => {
  const { newPassword, confirmNewPassword } = passwords;

  if (newPassword.length < 5) {
    return "Password is too short. It must be at least 5 characters.";
  }

  if (newPassword !== confirmNewPassword) {
    return "New password and confirm new password do not match. Please ensure you type your password correctly twice.";
  }

  return "";
};
