import Modal from "react-modal";
import { NewUsersTable } from "../NewUsersTable";

const ApproveUsersModalForm = ({ isOpen, closeModal }) => {
  const formClose = (event) => {
    event.preventDefault();
    closeModal();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel="Modal Form"
      className="modal-content"
      overlayClassName="modal-overlay"
    >
      <h3 className="form-title">APPROVE/DENY NEW USERS</h3>
      <NewUsersTable />
      <div className="form-buttons">
        <button className="cancel-button" type="button" onClick={formClose}>
          Close
        </button>
      </div>
    </Modal>
  );
};

export default ApproveUsersModalForm;
