import { useEffect, useState } from "react";
import { axios } from "../../axios";
import { useOrderContext } from "../../contexts/orderContext";
import Modal from "react-modal";
import { showToast } from "../../utils/showToast";

const DeleteOrderModalForm = ({ isOpen, closeModal }) => {
  const { selectedOrder } = useOrderContext();
  const [formData, setFormData] = useState({ ...selectedOrder });

  // Set initial form data
  useEffect(() => {
    try {
      setFormData({
        ...selectedOrder,
      });
    } catch (error) {}
  }, [selectedOrder]);

  // Send form data to back end upon form submit
  const formSubmit = async (event) => {
    event.preventDefault();

    // Send user details to user login API route
    try {
      const response = await axios.delete("orders/" + selectedOrder.orderId);
      if (response.data.success) {
        showToast("Order deleted.");
      }
    } catch (error) {
      console.error(error);
    }
    closeModal();
    window.location.reload();
  };

  // Exit modal form with no back end request
  const formCancel = (event) => {
    event.preventDefault();
    closeModal();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel="Modal Form"
      className="modal-content"
      overlayClassName="modal-overlay"
    >
      <form onSubmit={formSubmit}>
        <h3 className="form-title">DELETE ORDER</h3>
        <h3 className="form-label">
          Are you sure you want to delete the following order?
        </h3>
        <h3 className="form-label">
          {formData.unitNumber} - {formData.model} {formData.component} - WO#{" "}
          {formData.workOrderNumber}
        </h3>
        <div className="form-buttons">
          <button className="delete-button" type="submit">
            Delete
          </button>
          <button className="cancel-button" type="button" onClick={formCancel}>
            Cancel
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default DeleteOrderModalForm;
