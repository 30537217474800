import { useUserVerification } from "../hooks/useUserVerification";

export default function HomePage(props) {
  useUserVerification();

  return (
    <div className="login-page-container">
      <div className="loading-splash">
        <h1 className="company-name">LOADING</h1>
      </div>
    </div>
  );
}
