const { createContext, useState, useContext } = require("react");

const OrderSelectedContext = createContext();

export const OrderProvider = ({ children }) => {
  const [selectedOrder, setSelectedOrder] = useState(null);

  const updateSelectedOrder = (order) => {
    setSelectedOrder(order);
  };

  const clearSelectedOrder = () => {
    setSelectedOrder(null);
  };

  return (
    <OrderSelectedContext.Provider
      value={{ selectedOrder, updateSelectedOrder, clearSelectedOrder }}
    >
      {children}
    </OrderSelectedContext.Provider>
  );
};

export const useOrderContext = () => {
  return useContext(OrderSelectedContext);
};
