import { useState } from "react";
import Modal from "react-modal";
import { componentValidation } from "../../utils/componentValidation";
import { axios } from "../../axios";
import { showToast } from "../../utils/showToast";

const AddComponentModalForm = ({ isOpen, closeModal }) => {
  const [formData, setFormData] = useState({
    manufacturer: "",
    model: "",
    description: "",
    partNumber: "",
    pexPrice: "",
    dimensions: "",
    mass: "",
  });
  const manufacturerOptions = ["Caterpillar", "Liebherr"];
  const [models, setModels] = useState([]);

  // Fill models dropdown with relevant models based on manufacturer
  const updateModels = (manufacturer) => {
    switch (manufacturer) {
      case "Caterpillar":
        setModels(["789C", "793D"]);
        break;
      case "Liebherr":
        setModels(["R996"]);
        break;
      default:
        setModels([]);
    }
  };

  // Update form data upon form change
  const formChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    if (name === "manufacturer") {
      updateModels(value);
    }
  };

  // Send form data to back end upon form submit
  const formSubmit = async (event) => {
    event.preventDefault();

    // Validate the component details before submitting to back end
    let validationComment = componentValidation(formData);
    if (validationComment) {
      // ADD TOAST WITH "validationComment"
      showToast(validationComment);
      return;
    }

    // Send user details to user login API route
    try {
      const response = await axios.post("components", {
        ...formData,
      });

      // Based on response, log in user or display error
      if (response.data.success) {
        setFormData({
          ...formData,
          manufacturer: "",
          model: "",
          description: "",
          partNumber: "",
          pexPrice: "",
          dimensions: "",
          mass: "",
        });
      }
    } catch (error) {
      console.error(error);
    }
    closeModal();
    window.location.reload();
  };

  // Exit modal form with no back end request
  const formCancel = (event) => {
    event.preventDefault();
    closeModal();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel="Modal Form"
      className="modal-content"
      overlayClassName="modal-overlay"
    >
      <form onSubmit={formSubmit}>
        <h3 className="form-title">ADD NEW COMPONENT</h3>
        <div className="form-item">
          <h3 className="form-label">Manufacturer</h3>
          <select
            name="manufacturer"
            value={formData.manufacturer}
            onChange={formChange}
          >
            <option value=""></option>
            {manufacturerOptions.map((manufacturer, index) => (
              <option key={index} value={manufacturer}>
                {manufacturer}
              </option>
            ))}
          </select>
        </div>
        <div className="form-item">
          <h3 className="form-label">Model</h3>
          <select name="model" value={formData.model} onChange={formChange}>
            <option value=""></option>
            {models.map((model) => (
              <option key={model} value={model}>
                {model}
              </option>
            ))}
          </select>
        </div>
        <div className="form-item">
          <h3 className="form-label">Component</h3>
          <input
            type="text"
            name="description"
            value={formData.description}
            onChange={formChange}
          />
        </div>
        <div className="form-item">
          <h3 className="form-label">Part Number</h3>
          <input
            type="text"
            name="partNumber"
            value={formData.partNumber}
            onChange={formChange}
          />
        </div>
        <div className="form-item">
          <h3 className="form-label">PEX Price</h3>
          <input
            type="text"
            name="pexPrice"
            value={formData.pexPrice}
            onChange={formChange}
          />
        </div>
        <div className="form-item">
          <h3 className="form-label">Dimensions</h3>
          <input
            type="text"
            name="dimensions"
            value={formData.dimensions}
            onChange={formChange}
          />
        </div>
        <div className="form-item">
          <h3 className="form-label">Mass</h3>
          <input
            type="text"
            name="mass"
            value={formData.mass}
            onChange={formChange}
          />
        </div>
        <div className="form-buttons">
          <button className="submit-button" type="submit">
            Add
          </button>
          <button className="cancel-button" type="button" onClick={formCancel}>
            Cancel
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default AddComponentModalForm;
