export const componentValidation = (componentDetails) => {
  const { manufacturer, model, description, partNumber, pexPrice, mass } =
    componentDetails;

  if (manufacturer === "") {
    return "Please choose a manufacturer.";
  }

  if (model === "") {
    return "Please choose a model.";
  }

  if (description.length < 4) {
    return "Please enter a valid description.";
  }

  if (partNumber.length < 6) {
    return "Please enter a valid part number.";
  }

  if (/\D/.test(pexPrice)) {
    return "Please only enter numbers into the PEX price.";
  }

  if (/\D/.test(mass)) {
    return "Please only enter numbers into the PEX price.";
  }

  return "";
};
