import Modal from "react-modal";
import { axios } from "../../axios";
import { useEffect, useState } from "react";
import { updateDetailsValidation } from "../../utils/updateDetailsValidation";
import { showToast } from "../../utils/showToast";

const UpdateDetailsModalForm = ({ isOpen, closeModal }) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    site: "",
  });
  const [initialFormData, setInitialFormData] = useState({
    firstName: "",
    lastName: "",
    site: "",
  });
  const [isLoading, setIsLoading] = useState(true);

  const locationOptions = [
    "Rebuild Facility",
    "Caldera Operations",
    "Dagon Fel",
    "Khuul South Expansion",
    "Pelegaid North Expansion",
    "Seyda Neen Operations",
  ];

  const fetchData = async () => {
    try {
      // Retrieve the user's current information
      const userId = localStorage.getItem("userId");
      const userResponse = await axios.get("users?userId=" + userId);
      const user = userResponse.data.result;

      // Convert the site from an ID to a readable name
      const siteResponse = await axios.get("sites/" + user.site);

      setFormData({
        firstName: user.firstName,
        lastName: user.lastName,
        site: siteResponse.data.name,
      });
      setInitialFormData({
        firstName: user.firstName,
        lastName: user.lastName,
        site: siteResponse.data.name,
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (formData.firstName) {
      setIsLoading(false);
    }
  }, [formData]);

  const formSubmit = async (event) => {
    event.preventDefault();

    // Validate the details before submitting to back end
    let validationComment = updateDetailsValidation(formData);
    if (validationComment) {
      // ADD TOAST WITH "validationComment"
      showToast(validationComment);
      return;
    }

    // Send user details to user login API route
    try {
      const response = await axios.put("users/details", {
        ...formData,
      });
      showToast(response.data.message);

      // Based on response, log in user or display error
      if (response.data.success) {
        setFormData({
          ...formData,
          firstName: "",
          lastName: "",
          site: "",
        });
      }
    } catch (error) {
      console.error(error);
    }
    closeModal();
    window.location.reload();
  };

  // Update form data upon form change
  const formChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Exit modal form with no back end request
  const formCancel = (event) => {
    event.preventDefault();
    setFormData({ ...initialFormData });
    closeModal();
  };

  return (
    <div>
      {isLoading ? (
        <Modal
          isOpen={isOpen}
          onRequestClose={closeModal}
          contentLabel="Modal Form"
          className="modal-content"
          overlayClassName="modal-overlay"
        >
          <div className="loading-splash">
            <h1 className="loading-text">LOADING</h1>
          </div>
        </Modal>
      ) : (
        <Modal
          isOpen={isOpen}
          onRequestClose={closeModal}
          contentLabel="Modal Form"
          className="modal-content"
          overlayClassName="modal-overlay"
        >
          <form onSubmit={formSubmit}>
            <h3 className="form-title">UPDATE DETAILS</h3>
            <div className="form-item">
              <h3 className="form-label">First Name</h3>
              <input
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={formChange}
              />
            </div>
            <div className="form-item">
              <h3 className="form-label">Last Name</h3>
              <input
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={formChange}
              />
            </div>
            <div className="form-item">
              <h3 className="form-label">Site</h3>
              <select name="site" value={formData.site} onChange={formChange}>
                <option value=""></option>
                {locationOptions.map((site, index) => (
                  <option key={index} value={site}>
                    {site}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-buttons">
              <button className="submit-button" type="submit">
                Update
              </button>
              <button
                className="cancel-button"
                type="button"
                onClick={formCancel}
              >
                Cancel
              </button>
            </div>
          </form>
        </Modal>
      )}
    </div>
  );
};

export default UpdateDetailsModalForm;
