import { useEffect, useState } from "react";
import AddComponentModalForm from "./modalforms/AddComponentModalForm";
import ModifyComponentModalForm from "./modalforms/ModifyComponentModalForm";
import RemoveComponentModalForm from "./modalforms/RemoveComponentModalForm";
import { useComponentContext } from "../contexts/componentContext";
import { axios } from "../axios";

export function ComponentsButtons() {
  const [isAddComponentModalOpen, setAddComponentModalOpen] = useState(false);
  const [isModifyComponentModalOpen, setModifyComponentModalOpen] =
    useState(false);
  const [isRemoveComponentModalOpen, setRemoveComponentModalOpen] =
    useState(false);
  const { selectedComponent } = useComponentContext();
  const [adminUser, setAdminUser] = useState(false);

  // Retrieve user details to check for admin
  const fetchData = async () => {
    const user = await axios.get(
      "users?userId=" + localStorage.getItem("userId")
    );
    const response = await axios.get("roles/" + user.data.result.role);

    if (response.data.role === "admin") {
      setAdminUser(true);
    }
  };
  useEffect(() => {
    fetchData();
  }, [adminUser]);

  const openAddComponentModal = () => {
    setAddComponentModalOpen(true);
  };
  const openModifyComponentModal = () => {
    if (selectedComponent) {
      setModifyComponentModalOpen(true);
    }
  };
  const openRemoveComponentModal = () => {
    if (selectedComponent) {
      setRemoveComponentModalOpen(true);
    }
  };
  const closeAddComponentModal = () => {
    setAddComponentModalOpen(false);
  };
  const closeModifyComponentModal = () => {
    setModifyComponentModalOpen(false);
  };
  const closeRemoveComponentModal = () => {
    setRemoveComponentModalOpen(false);
  };

  return (
    <div className="table-buttons-container">
      <button
        disabled={!adminUser}
        className="table-button"
        onClick={openAddComponentModal}
      >
        Add Component
      </button>
      <button
        disabled={!adminUser || !selectedComponent}
        className="table-button"
        onClick={openModifyComponentModal}
      >
        Modify Component
      </button>
      <button
        disabled={!adminUser || !selectedComponent}
        className="table-button"
        onClick={openRemoveComponentModal}
      >
        Remove Component
      </button>

      <AddComponentModalForm
        isOpen={isAddComponentModalOpen}
        closeModal={closeAddComponentModal}
      />
      <ModifyComponentModalForm
        isOpen={isModifyComponentModalOpen}
        closeModal={closeModifyComponentModal}
      />
      <RemoveComponentModalForm
        isOpen={isRemoveComponentModalOpen}
        closeModal={closeRemoveComponentModal}
      />
    </div>
  );
}
