import { useNavigate } from "react-router-dom";
import { axios } from "../axios";
import { showToast } from "../utils/showToast";

export function NavBar() {
  const navigate = useNavigate();

  const homeButton = () => {
    navigate("/dash");
  };

  const settingsButton = () => {
    navigate("/settings");
  };

  const logoutButton = async () => {
    // Invalidate cookie with logout route
    const response = await axios.post("users/logout");
    localStorage.clear();
    showToast(response.data.message);

    // Return to the login page
    navigate("/login");
  };

  return (
    <div className="navbar">
      <h1 className="company-name-navbar">REDORAN MINING CO.</h1>
      <div className="nav-buttons">
        <img
          src="home.png"
          alt="Home"
          onClick={homeButton}
          className="nav-button"
        />
        <img
          src="gear.png"
          alt="Settings"
          onClick={settingsButton}
          className="nav-button"
        />
        <img
          src="logout.png"
          alt="Logout"
          onClick={logoutButton}
          className="nav-button"
        />
      </div>
    </div>
  );
}
