import { ComponentsButtons } from "../components/ComponentsButtons";
import { ComponentsTable } from "../components/ComponentsTable";
import { NavBar } from "../components/NavBar";
import { useUserVerification } from "../hooks/useUserVerification";
import { ComponentProvider } from "../contexts/componentContext";

export default function ComponentsPage(props) {
  useUserVerification();
  return (
    <ComponentProvider>
      <div className="app-page-container">
        <NavBar />
        <div className="page-title-container">
          <h1 className="page-title">COMPONENT CATALOGUE</h1>
        </div>
        <ComponentsButtons />
        <ComponentsTable />{" "}
      </div>
    </ComponentProvider>
  );
}
