import { NavBar } from "../components/NavBar";
import { useUserVerification } from "../hooks/useUserVerification";
import { OrdersTable } from "../components/OrdersTable";
import { OrdersButtons } from "../components/OrdersButtons";
import { OrderProvider } from "../contexts/orderContext";

export default function OrdersPage(props) {
  useUserVerification();
  return (
    <OrderProvider>
      <div className="app-page-container">
        <NavBar />
        <div className="page-title-container">
          <h1 className="page-title">ACTIVE ORDER LIST</h1>
        </div>
        <OrdersButtons />
        <OrdersTable />
      </div>
    </OrderProvider>
  );
}
