import { Link } from "react-router-dom";

export function DashSelector() {
  return (
    <div id="dash-selector">
      <Link to="/orders">
        <div className="picture-link-orders">
          <div className="dash-title-container">
            <h1 className="dash-title">ORDERS</h1>
          </div>
          <div className="dash-text">
            <h3>Place component orders and view active orders</h3>
          </div>
        </div>
      </Link>
      <Link to="/components">
        <div className="picture-link-components">
          <div className="dash-title-container">
            <h1 className="dash-title">COMPONENTS</h1>
          </div>
          <div className="dash-text">
            <h3>View supported component list</h3>
          </div>
        </div>
      </Link>
    </div>
  );
}
