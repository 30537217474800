import { useEffect } from "react";
import { axios } from "../axios";
import { useLocation, useNavigate } from "react-router-dom";

export const useUserVerification = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const verifyCookie = async () => {
      try {
        const response = await axios.post("users/verify");
        localStorage.setItem("userId", response.data.userId);
        localStorage.setItem("userRole", response.data.role);
        if (!response.data.status) {
          navigate("/login");
        } else if (location.pathname === "/") {
          navigate("/dash");
        }
      } catch (error) {
        console.log(error);
      }
    };
    verifyCookie();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
