import { useEffect, useState } from "react";
import { useOrderContext } from "../../contexts/orderContext";
import { axios } from "../../axios";
import Modal from "react-modal";
import { showToast } from "../../utils/showToast";
import { statusValidation } from "../../utils/statusValidation";

const StatusOrderModalForm = ({ isOpen, closeModal }) => {
  const { selectedOrder } = useOrderContext();
  const [formData, setFormData] = useState({
    status: "",
  });

  const statusOptions = ["New", "Actioned", "Completed"];

  // Update form data upon form change
  const formChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Set initial form data
  useEffect(() => {
    try {
      setFormData({
        ...selectedOrder,
        status: "",
      });
    } catch (error) {}
  }, [selectedOrder]);

  // Send form data to back end upon form submit
  const formSubmit = async (event) => {
    event.preventDefault();

    // Validate the order details before submitting to back end
    let validationComment = statusValidation(formData);
    if (validationComment) {
      showToast(validationComment);
      return;
    }

    // Send user details to user login API route
    try {
      const response = await axios.patch("orders/" + formData.orderId, {
        ...formData,
      });

      // Based on response, log in user or display error
      if (response.data.success) {
        setFormData({
          ...formData,
          status: "",
        });
      }
    } catch (error) {
      console.error(error);
    }
    closeModal();
    window.location.reload();
  };

  // Exit modal form with no back end request
  const formCancel = (event) => {
    event.preventDefault();
    closeModal();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel="Modal Form"
      className="modal-content"
      overlayClassName="modal-overlay"
    >
      <form onSubmit={formSubmit}>
        <h3 className="form-title">UPDATE ORDER STATUS</h3>
        <h3 className="form-label">
          Updating the status for the following order:
        </h3>
        <h3 className="form-label">
          {formData.unitNumber} - {formData.model} {formData.component}
        </h3>
        <div className="form-item">
          <h3 className="form-label">Status</h3>
          <select name="status" value={formData.status} onChange={formChange}>
            <option value=""></option>
            {statusOptions.map((status, index) => (
              <option key={index} value={status}>
                {status}
              </option>
            ))}
          </select>
        </div>
        <div className="form-buttons">
          <button className="submit-button" type="submit">
            Update
          </button>
          <button className="cancel-button" type="button" onClick={formCancel}>
            Cancel
          </button>
        </div>
      </form>
    </Modal>
  );
};
export default StatusOrderModalForm;
