import { NavBar } from "../components/NavBar";
import { SettingsButtons } from "../components/SettingsButtons";
import { useUserVerification } from "../hooks/useUserVerification";

export default function UserSettingsPage(props) {
  useUserVerification();
  return (
    <div className="app-page-container">
      <NavBar />
      <div className="page-title-container">
        <h1 className="page-title">SETTINGS</h1>
      </div>
      <SettingsButtons />
    </div>
  );
}
