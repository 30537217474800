import { useEffect, useState } from "react";
import { axios } from "../../axios";
import { useOrderContext } from "../../contexts/orderContext";
import { orderValidation } from "../../utils/orderValidation";
import Modal from "react-modal";
import { showToast } from "../../utils/showToast";

const ModifyOrderModalForm = ({ isOpen, closeModal }) => {
  const { selectedOrder } = useOrderContext();
  const [formData, setFormData] = useState({
    ...selectedOrder,
  });

  const manufacturerOptions = ["Caterpillar", "Liebherr"];
  const priorityOptions = ["Breakdown", "Opportune", "Next Service"];
  const [models, setModels] = useState([]);
  const [components, setComponents] = useState([]);

  // Fill models dropdown with relevant models based on manufacturer
  const updateModels = (manufacturer) => {
    switch (manufacturer) {
      case "Caterpillar":
        setModels(["789C", "793D"]);
        break;
      case "Liebherr":
        setModels(["R996"]);
        break;
      default:
        setModels([]);
    }
  };

  const updateComponents = async (model) => {
    const response = await axios.get("components?model=" + model);
    setComponents(
      response.data.result.map((component) => component.description)
    );
  };

  // Update form data upon form change
  const formChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    if (name === "manufacturer") {
      updateModels(value);
    } else if (name === "model") {
      updateComponents(value);
    }
  };

  // Set initial form data
  useEffect(() => {
    try {
      updateModels(selectedOrder.manufacturer);
    } catch (error) {}
    try {
      updateComponents(selectedOrder.model);
    } catch (error) {}
    try {
      setFormData({
        ...selectedOrder,
        description: selectedOrder.component,
      });
    } catch (error) {}
  }, [selectedOrder]);

  // Send form data to back end upon form submit
  const formSubmit = async (event) => {
    event.preventDefault();

    // Validate the component details before submitting to back end
    let validationComment = orderValidation(formData);
    if (validationComment) {
      // ADD TOAST WITH "validationComment"
      showToast(validationComment);
      return;
    }

    // Send user details to user login API route
    try {
      const response = await axios.put("orders/" + formData.orderId, {
        ...formData,
      });

      // Based on response, log in user or display error
      if (response.data.success) {
        showToast("Order details updated.");
        setFormData({
          ...formData,
          manufacturer: "",
          model: "",
          description: "",
          unitNumber: "",
          workOrderNumber: "",
          priority: "",
          notes: "",
        });
      }
    } catch (error) {
      console.error(error);
    }
    closeModal();
    window.location.reload();
  };

  // Exit modal form with no back end request
  const formCancel = (event) => {
    event.preventDefault();
    closeModal();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel="Modal Form"
      className="modal-content"
      overlayClassName="modal-overlay"
    >
      <form onSubmit={formSubmit}>
        <h3 className="form-title">MODIFY ORDER DETAILS</h3>
        <div className="form-item">
          <h3 className="form-label">Manufacturer</h3>
          <select
            name="manufacturer"
            value={formData.manufacturer}
            onChange={formChange}
          >
            <option value=""></option>
            {manufacturerOptions.map((manufacturer, index) => (
              <option key={index} value={manufacturer}>
                {manufacturer}
              </option>
            ))}
          </select>
        </div>
        <div className="form-item">
          <h3 className="form-label">Model</h3>
          <select name="model" value={formData.model} onChange={formChange}>
            <option value=""></option>
            {models.map((model) => (
              <option key={model} value={model}>
                {model}
              </option>
            ))}
          </select>
        </div>
        <div className="form-item">
          <h3 className="form-label">Component</h3>
          <select
            name="description"
            value={formData.description}
            onChange={formChange}
          >
            <option value=""></option>
            {components.map((component) => (
              <option key={component} value={component}>
                {component}
              </option>
            ))}
          </select>
        </div>
        <div className="form-item">
          <h3 className="form-label">Unit Number</h3>
          <input
            type="text"
            name="unitNumber"
            value={formData.unitNumber}
            onChange={formChange}
          />
        </div>
        <div className="form-item">
          <h3 className="form-label">Work Order No.</h3>
          <input
            type="text"
            name="workOrderNumber"
            value={formData.workOrderNumber}
            onChange={formChange}
          />
        </div>
        <div className="form-item">
          <h3 className="form-label">Priority</h3>
          <select
            name="priority"
            value={formData.priority}
            onChange={formChange}
          >
            <option value=""></option>
            {priorityOptions.map((priority, index) => (
              <option key={index} value={priority}>
                {priority}
              </option>
            ))}
          </select>
        </div>
        <div className="form-item">
          <h3 className="form-label">Notes</h3>
          <textarea
            type="text"
            name="notes"
            value={formData.notes}
            onChange={formChange}
            rows="3"
          />
        </div>
        <div className="form-buttons">
          <button className="submit-button" type="submit">
            Modify
          </button>
          <button className="cancel-button" type="button" onClick={formCancel}>
            Cancel
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default ModifyOrderModalForm;
