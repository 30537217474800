import { useState } from "react";
import { axios } from "../axios";
import { Link, useNavigate } from "react-router-dom";
import { showToast } from "../utils/showToast";

export function LoginForm() {
  const navigate = useNavigate();

  // Set login details on form input change
  const [loginDetails, setLoginDetails] = useState({
    email: "",
    password: "",
  });
  const { email, password } = loginDetails;
  const formInputChange = (event) => {
    const { name, value } = event.target;
    setLoginDetails({
      ...loginDetails,
      [name]: value,
    });
  };

  // Function to run upon clicking the login submit button
  const loginButton = async (event) => {
    event.preventDefault();
    // Send user details to user login API route
    try {
      const response = await axios.post("users/login", {
        email: loginDetails.email.toLowerCase(),
        password: loginDetails.password,
      });
      showToast(response.data.message);

      // Based on response, log in user or display error
      if (response.data.success) {
        setLoginDetails({
          ...loginDetails,
          email: "",
          password: "",
        });
        navigate("/dash");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="form-container">
      <h1 className="company-name">REDORAN MINING CO.</h1>
      <h3 className="form-title">INTERNAL COMPONENT ORDERING</h3>
      <form onSubmit={loginButton}>
        <div className="form-item">
          <h3 className="form-label">Email</h3>
          <input
            type="text"
            name="email"
            value={email}
            onChange={formInputChange}
          />
        </div>
        <div className="form-item">
          <h3 className="form-label">Password</h3>
          <input
            type="password"
            name="password"
            value={password}
            onChange={formInputChange}
          />
        </div>
        <div className="form-buttons">
          <button className="submit-button" type="submit">
            LOGIN
          </button>
        </div>
      </form>
      <div className="link">
        New user?{" "}
        <Link to={"/register"}>
          <b>Register</b>
        </Link>
      </div>
    </div>
  );
}
