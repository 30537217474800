import { useState } from "react";
import { axios } from "../axios";
import { Link, useNavigate } from "react-router-dom";
import { registerValidation } from "../utils/registerValidation";
import { showToast } from "../utils/showToast";

export function RegisterForm() {
  const navigate = useNavigate();

  // Set login details on form input change
  const [registerDetails, setRegisterDetails] = useState({
    firstName: "",
    lastName: "",
    site: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const { firstName, lastName, site, email, password, confirmPassword } =
    registerDetails;

  const locationOptions = [
    "Rebuild Facility",
    "Caldera Operations",
    "Dagon Fel",
    "Khuul South Expansion",
    "Pelegaid North Expansion",
    "Seyda Neen Operations",
  ];

  const formInputChange = (event) => {
    const { name, value } = event.target;
    setRegisterDetails({
      ...registerDetails,
      [name]: value,
    });
  };

  // Function to run upon clicking the register
  const registerButton = async (event) => {
    event.preventDefault();

    // Validate register details before submitted to back end
    let validationComment = registerValidation(registerDetails);
    if (validationComment) {
      // ADD TOAST WITH "validationComment"
      showToast(validationComment);
      return;
    }

    // Send user details to user login API route
    try {
      const response = await axios.post("users/register", {
        ...registerDetails,
        email: registerDetails.email.toLowerCase(),
      });
      showToast(response.data.message);

      // Based on response, log in user or display error
      if (response.data.success) {
        setRegisterDetails({
          ...registerDetails,
          firstName: "",
          lastName: "",
          site: "",
          email: "",
          password: "",
          confirmPassword: "",
        });
        navigate("/login");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="form-container">
      <h1 className="company-name">REDORAN MINING CO.</h1>
      <h3 className="form-title">INTERNAL COMPONENT ORDERING</h3>
      <form onSubmit={registerButton}>
        <div className="form-item">
          <h3 className="form-label">First Name</h3>
          <input
            type="text"
            name="firstName"
            value={firstName}
            onChange={formInputChange}
          />
        </div>
        <div className="form-item">
          <h3 className="form-label">Last Name</h3>
          <input
            type="text"
            name="lastName"
            value={lastName}
            onChange={formInputChange}
          />
        </div>
        <div className="form-item">
          <h3 className="form-label">Location</h3>
          <select name="site" value={site} onChange={formInputChange}>
            <option value=""></option>
            {locationOptions.map((location, index) => (
              <option key={index} value={location}>
                {location}
              </option>
            ))}
          </select>
        </div>
        <div className="form-item">
          <h3 className="form-label">Email</h3>
          <input
            type="text"
            name="email"
            value={email}
            onChange={formInputChange}
          />
        </div>
        <div className="form-item">
          <h3 className="form-label">Password</h3>
          <input
            type="password"
            name="password"
            value={password}
            onChange={formInputChange}
          />
        </div>
        <div className="form-item">
          <h3 className="form-label">Confirm Password</h3>
          <input
            type="password"
            name="confirmPassword"
            value={confirmPassword}
            onChange={formInputChange}
          />
        </div>
        <div className="form-buttons">
          <button className="submit-button" type="submit">
            REGISTER
          </button>
        </div>
        <div className="link">
          Already signed up?{" "}
          <Link to={"/login"}>
            <b>Login</b>
          </Link>
        </div>
      </form>
    </div>
  );
}
