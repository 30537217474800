import { useState } from "react";
import Modal from "react-modal";
import { changePasswordValidation } from "../../utils/changePasswordValidation";
import { axios } from "../../axios";
import { showToast } from "../../utils/showToast";

const ChangePasswordModalForm = ({ isOpen, closeModal }) => {
  const [formData, setFormData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });

  const formChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Send form data to back end upon form submit
  const formSubmit = async (event) => {
    event.preventDefault();

    // Validate the details before submitting to back end
    let validationComment = changePasswordValidation(formData);
    if (validationComment) {
      // ADD TOAST WITH "validationComment"
      showToast(validationComment);
      return;
    }

    // Send user details to user login API route
    try {
      const response = await axios.put("users/password", {
        oldPassword: formData.currentPassword,
        newPassword: formData.newPassword,
      });
      showToast(response.data.message);

      // Based on response, log in user or display error
      if (response.data.success) {
        setFormData({
          ...formData,
          currentPassword: "",
          newPassword: "",
          confirmNewPassword: "",
        });
      }
    } catch (error) {
      console.error(error);
    }
    closeModal();
    window.location.reload();
  };

  // Exit modal form with no back end request
  const formCancel = (event) => {
    event.preventDefault();
    closeModal();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel="Modal Form"
      className="modal-content"
      overlayClassName="modal-overlay"
    >
      <form onSubmit={formSubmit}>
        <h3 className="form-title">CHANGE PASSWORD</h3>
        <div className="form-item">
          <h3 className="form-label">Current Password</h3>
          <input
            type="password"
            name="currentPassword"
            value={formData.currentPassword}
            onChange={formChange}
          />
        </div>
        <div className="form-item">
          <h3 className="form-label">New Password</h3>
          <input
            type="password"
            name="newPassword"
            value={formData.newPassword}
            onChange={formChange}
          />
        </div>
        <div className="form-item">
          <h3 className="form-label">Confirm New Password</h3>
          <input
            type="password"
            name="confirmNewPassword"
            value={formData.confirmNewPassword}
            onChange={formChange}
          />
        </div>
        <div className="form-buttons">
          <button className="submit-button" type="submit">
            Change
          </button>
          <button className="cancel-button" type="button" onClick={formCancel}>
            Cancel
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default ChangePasswordModalForm;
