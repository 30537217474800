import { createContext, useContext, useState } from "react";

const ComponentSelectedContext = createContext();

export const ComponentProvider = ({ children }) => {
  const [selectedComponent, setSelectedComponent] = useState(null);

  const updateSelectedComponent = (component) => {
    setSelectedComponent(component);
  };

  const clearSelectedRow = () => {
    setSelectedComponent(null);
  };

  return (
    <ComponentSelectedContext.Provider
      value={{ selectedComponent, updateSelectedComponent, clearSelectedRow }}
    >
      {children}
    </ComponentSelectedContext.Provider>
  );
};

export const useComponentContext = () => {
  return useContext(ComponentSelectedContext);
};
