import { useState } from "react";
import CreateOrderModalForm from "./modalforms/CreateOrderModalForm";
import ModifyOrderModalForm from "./modalforms/ModifyOrderModalForm";
import DeleteOrderModalForm from "./modalforms/DeleteOrderModalForm";
import StatusOrderModalForm from "./modalforms/StatusOrderModalForm";
import { useOrderContext } from "../contexts/orderContext";

export function OrdersButtons() {
  const [isCreateOrderModalOpen, setCreateOrderModalOpen] = useState(false);
  const [isModifyOrderModalOpen, setModifyOrderModalOpen] = useState(false);
  const [isDeleteOrderModalOpen, setDeleteOrderModalOpen] = useState(false);
  const [isStatusOrderModalOpen, setStatusOrderModalOpen] = useState(false);
  const { selectedOrder } = useOrderContext();
  const rebuildUserRole = localStorage.getItem("userRole") === "rebuild";
  const siteUserRole = localStorage.getItem("userRole") === "site";

  const openCreateOrderModal = () => {
    setCreateOrderModalOpen(true);
  };
  const openModifyOrderModal = () => {
    if (selectedOrder) {
      setModifyOrderModalOpen(true);
    }
  };
  const openDeleteOrderModal = () => {
    if (selectedOrder) {
      setDeleteOrderModalOpen(true);
    }
  };
  const openStatusOrderModal = () => {
    if (selectedOrder) {
      setStatusOrderModalOpen(true);
    }
  };
  const closeCreateOrderModal = () => {
    setCreateOrderModalOpen(false);
  };
  const closeModifyOrderModal = () => {
    setModifyOrderModalOpen(false);
  };
  const closeDeleteOrderModal = () => {
    setDeleteOrderModalOpen(false);
  };
  const closeStatusOrderModal = () => {
    setStatusOrderModalOpen(false);
  };

  return (
    <div className="table-buttons-container">
      <button
        disabled={!siteUserRole}
        className="table-button"
        onClick={openCreateOrderModal}
      >
        Create Order
      </button>
      <button
        disabled={!siteUserRole || !selectedOrder}
        className="table-button"
        onClick={openModifyOrderModal}
      >
        Modify Order
      </button>
      <button
        disabled={!siteUserRole || !selectedOrder}
        className="table-button"
        onClick={openDeleteOrderModal}
      >
        Delete Order
      </button>
      <button
        disabled={!rebuildUserRole || !selectedOrder}
        className="table-button"
        onClick={openStatusOrderModal}
      >
        Update Status
      </button>

      <CreateOrderModalForm
        isOpen={isCreateOrderModalOpen}
        closeModal={closeCreateOrderModal}
      />
      <ModifyOrderModalForm
        isOpen={isModifyOrderModalOpen}
        closeModal={closeModifyOrderModal}
      />
      <DeleteOrderModalForm
        isOpen={isDeleteOrderModalOpen}
        closeModal={closeDeleteOrderModal}
      />
      <StatusOrderModalForm
        isOpen={isStatusOrderModalOpen}
        closeModal={closeStatusOrderModal}
      />
    </div>
  );
}
