import Modal from "react-modal";
import { axios } from "../../axios";
import { useComponentContext } from "../../contexts/componentContext";
import { useEffect, useState } from "react";
import { showToast } from "../../utils/showToast";

const RemoveComponentModalForm = ({ isOpen, closeModal }) => {
  const { selectedComponent } = useComponentContext();
  const [formData, setFormData] = useState({
    ...selectedComponent,
  });

  // Set initial form data
  useEffect(() => {
    setFormData({
      ...selectedComponent,
    });
  }, [selectedComponent]);

  // Delete component by ID upon form submission
  const formSubmit = async (event) => {
    event.preventDefault();

    // Send user details to user login API route
    try {
      const response = await axios.delete("components/" + formData.componentId);
      showToast(response.data.message);
    } catch (error) {
      console.error(error);
    }
    closeModal();
    window.location.reload();
  };

  // Exit modal form with no back end request
  const formCancel = (event) => {
    event.preventDefault();
    closeModal();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel="Modal Form"
      className="modal-content"
      overlayClassName="modal-overlay"
    >
      <form onSubmit={formSubmit}>
        <h3 className="form-title">REMOVE COMPONENT</h3>
        <h3 className="form-label">
          Are you sure you want to remove the following component?
        </h3>
        <h3 className="form-label">
          {formData.manufacturer} - {formData.model} - {formData.description} -{" "}
          {formData.partNumber}
        </h3>
        <div className="form-buttons">
          <button className="delete-button" type="submit">
            Remove
          </button>
          <button className="cancel-button" type="button" onClick={formCancel}>
            Cancel
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default RemoveComponentModalForm;
