export const registerValidation = (registerDetails) => {
  const { firstName, lastName, location, email, password, confirmPassword } =
    registerDetails;

  if (firstName.length < 2) {
    return "First name is too short.";
  }

  if (lastName.length < 2) {
    return "Last name is too short.";
  }

  if (location === "") {
    return "Please select a location.";
  }

  if (!email.toLowerCase().includes("@redoran.com.au")) {
    return "You must use a valid Redoran Mining email address. These will end with '@redoran.com.au'.";
  }

  if (email.length < 18) {
    return "Email is too short.";
  }

  if (password.length < 5) {
    return "Password is too short. It must be at least 5 characters.";
  }

  if (password !== confirmPassword) {
    return "Password and confirm password do not match. Please ensure you type your password correctly twice.";
  }

  return "";
};
