import { NavBar } from "../components/NavBar";
import { DashSelector } from "../components/DashSelector";
import { useUserVerification } from "../hooks/useUserVerification";

export default function DashboardPage(props) {
  useUserVerification();

  return (
    <div className="app-page-container">
      <NavBar />
      <div className="page-title-container">
        <h1 className="page-title">DASHBOARD</h1>
      </div>
      <DashSelector />
    </div>
  );
}
