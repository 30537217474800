import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { axios } from "../axios";
import { useOrderContext } from "../contexts/orderContext";

export function OrdersTable() {
  const [data, setData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [loading, setLoading] = useState(true);
  const { updateSelectedOrder } = useOrderContext();

  const fetchData = async () => {
    try {
      const response = await axios.get("orders");
      setData(response.data.result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (data.length > 0) {
      setLoading(false);
    }
  }, [data]);

  useEffect(() => {
    const fetchTimeout = setTimeout(() => {
      if (loading) {
        setLoading(false);
      }
    }, 5000);
    return () => clearTimeout(fetchTimeout);
  }, [loading]);

  const handleRadioChange = (index) => {
    setSelectedRow(index);
    updateSelectedOrder(data[index]);
  };

  return (
    <div>
      {loading ? (
        <div className="loading-splash">
          <h1 className="loading-text">LOADING</h1>
        </div>
      ) : (
        <div className="table-container">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Select</th>
                <th>Order Status</th>
                <th>Site</th>
                <th>Unit Number</th>
                <th>Manufacturer</th>
                <th>Model</th>
                <th>Component</th>
                <th>Work Order Number</th>
                <th>Priority</th>
                <th>Notes</th>
              </tr>
            </thead>
            <tbody>
              {data.map((row, index) => (
                <tr key={index}>
                  <td>
                    <input
                      type="radio"
                      name="selectRow"
                      checked={selectedRow === index}
                      onChange={() => handleRadioChange(index)}
                    />
                  </td>
                  {Object.entries(row).map(
                    ([key, value], columnIndex) =>
                      key !== "orderId" && <td key={columnIndex}>{value}</td>
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
    </div>
  );
}
