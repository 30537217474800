export const updateDetailsValidation = (userDetails) => {
  const { firstName, lastName, site } = userDetails;

  if (firstName.length < 2) {
    return "Enter a valid first name.";
  }

  if (lastName.length < 2) {
    return "Enter a valid last name";
  }

  if (site === "") {
    return "Please choose a site.";
  }

  return "";
};
